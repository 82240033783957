import { PostOrPage } from "@tryghost/content-api"
import { format } from "date-fns"
import Link from "next/link"
import React from "react"

export interface Props {
  post: PostOrPage
}

export function BlogCard({ post }: Props) {
  return (
    <div
      key={post.title}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer
      hover:transform hover:scale-[1.01] hover:shadow-xl transition-all duration-300 ease-in-out"
    >
      {post.feature_image && (
        <div className="flex-shrink-0">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <Link href={`/blog/${post.slug}`}>
            <img
              className="h-48 w-full object-cover"
              src={post.feature_image}
              alt="post featured image"
            />
          </Link>
        </div>
      )}
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1 gap-1">
          <Link href={`/blog/${post.slug}`} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{post.title}</p>
            {post.plaintext && (
              <div
                className="mt-3 text-base text-gray-500 line-clamp-3"
                dangerouslySetInnerHTML={{ __html: post.plaintext }}
              />
            )}
          </Link>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex space-x-1 text-sm text-gray-500">
            {post.published_at && (
              <time dateTime={post.published_at}>
                {format(new Date(post.published_at), "MMM d, yyyy")}
              </time>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
